<template>
  <b-card>
    <div class="d-flex align-items-start mb-2">
      <b-button
        variant="primary"
        size="lg"
        class="mr-2 rounded-lg p-0"
        @click="$router.push('/')"
      >
        <feather-icon
          size="2x"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <div class="text-black">
        <h2 class="font-bold">
          Pengaturan Pembayaran
        </h2>
        <span>Kita akan secara otomatis merekomendasikan metode pembayaran terbaik yang dapat digunakan ketika saldo KomPay-mu tidak cukup untuk bertransaksi</span>
      </div>
    </div>
    <b-row class="mx-1 text-black">
      <b-col cols="12">
        <div class="border rounded-lg">
          <div class="d-flex w-100 justify-between bg-[#FCD4BE] border rounded-t-lg p-2">
            <h3 class="font-bold text-black">
              Penggunaan KomPoints
            </h3>
            <div id="tooltipPayment">
              <b-form-checkbox
                v-model="isPayment"
                switch
                @change="setCustomPayment"
              />
            </div>
          </div>
          <div class="mb-2 p-2">
            <div class="mb-2">
              Kompoints yang akan digunakan apabila saldo KomPay-mu tidak cukup untuk melakukan transaksi
            </div>
            <b-row>
              <b-col cols="4">
                <label for="input-kompoint">
                  <div class="mb-[5px] text-black text-[14px]">
                    Nominal minimal yang digunakan
                  </div>
                  <small class="text-[#828282]">Nominal minimal yang digunakan</small>
                </label>
              </b-col>
              <b-col
                cols="4"
                class="align-self-center"
              >
                <b-form-input
                  id="input-kompoint"
                  v-model="minimalKompoints"
                  type="number"
                  :state="reqInput"
                  placeholder="10000"
                  :disabled="!isDisabledInput"
                  @keypress="isNumber($event)"
                  @paste.prevent="pasteInput($event)"
                  @blur="handleInput"
                />
              </b-col>
            </b-row>
            <div class="text-end mt-3">
              <b-button
                :variant="minimalKompoints < 10000 || oldKompoints === Number(minimalKompoints) || !isPayment ? 'secondary' : 'primary'"
                class="rounded-lg"
                :disabled="minimalKompoints < 10000 || oldKompoints === Number(minimalKompoints) || !isPayment"
                @click="submit"
              >
                Terapkan
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapState } from 'vuex'
import { isNumber } from '@/libs/helpers'

export default {
  components: {},
  data() {
    return {
      isPayment: false,
      minimalKompoints: 0,
      oldKompoints: 0,
      isDisabledInput: false,
      isDisabled: false,
      isNumber,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
    reqInput() {
      return !(this.minimalKompoints < 10000)
    },
  },
  mounted() {
    if (this.profile.role_id !== 4) {
      this.$router.go(-1)
    } else {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      this.$http.get('/v1/payment-setting')
        .then(response => {
          const { data } = response.data
          this.isPayment = data.is_active_kompoints === 1
          this.oldKompoints = data.min_usage_kompoints
          this.minimalKompoints = data.min_usage_kompoints === 0 ? 10000 : data.min_usage_kompoints
          if (this.isPayment) this.isDisabledInput = true
        })
    },
    setCustomPayment() {
      this.isDisabledInput = !this.isDisabledInput
      this.submit()
    },
    submit() {
      const body = {
        is_active: this.isPayment ? 1 : 0,
      }
      if (this.isPayment) {
        Object.assign(body, { min_usage: this.minimalKompoints })
      }
      this.$http.put('/v1/payment-setting/update', body)
        .then(() => {
          this.fetchData()
          this.$toast_success({ message: 'Berhasil melakukan perubahan' })
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal melakukan perubahan' })
        })
    },
    pasteInput(event) {
      const pastedData = event.clipboardData.getData('text')
      const numericValue = pastedData.replace(/[^0-9]/g, '')

      if (!Number.isNaN(numericValue)) {
        this.minimalKompoints = numericValue
      }
    },
    handleInput(event) {
      if (event.target.value.length > 6) {
        this.minimalKompoints = event.target.value.slice(0, 6)
      }
    },
  },
}
</script>
